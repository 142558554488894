import axios from "axios";
import app from "@/store/app";

const config = window.rs_kakao;

export default class Authentication {
  get isAuthenticated(): boolean {
    return this.tokens.authenticated;
  }

  get tokens(): LoginSuccess {
    const storage = localStorage.getItem("authentication");
    if (storage === null) {
      return {
        authenticated: false,
        access_token: "",
        id_token: "",
        language: "en_US"
      };
    }
    return JSON.parse(storage);
  }

  get openId(): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    const openIdFromUrl = urlParams.get("open_id");

    const openId = localStorage.getItem("openId") ?? openIdFromUrl;
    return openId;
  }

  public async authorize(email: string, password: string) {
    if (this.openId) {
      const response = await axios({
        baseURL: config.base_url,
        url: `/login`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: { email, password, openID: this.openId }
      });

      const authData = response.data as LoginSuccess;
      this.setAuthentication(authData, this.openId);
      return authData;
    } else throw new Error();
  }

  public async lambdaAuthorize(access_token: string, type: string) {
    if (this.openId) {
      const response = await axios({
        baseURL: config.base_url,
        url: `/login`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`
        },
        data: {
          openID: this.openId,
          type
        }
      });

      const authData = response.data as LoginSuccess;
      if (type === "login") {
        this.setAuthentication(authData, this.openId);
      } else if (type === "logout") {
        localStorage.removeItem("authentication");
      }
    } else throw new Error();
  }

  private setAuthentication(data: LoginSuccess, openId: string) {
    localStorage.setItem("authentication", JSON.stringify(data));
    localStorage.setItem("openId", openId);
  }

  public async logout() {
    if (!this.openId) {
      window.alert(
        "알 수 없는 오류가 발생했습니다. 다시 시도하거나 계정 담당자에게 연락하세요."
      );
      return;
    }

    const data = { type: "logout", openID: this.openId };
    await axios({
      url: `${config.base_url}/login`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data
    });

    await localStorage.removeItem("authentication");
  }
  public async checkLoggedIn() {
    try {
      app.SET_IS_LOADING(true);
      const checkAuthResponse = await axios({
        url: `${config.base_url}/login`,
        method: "POST",
        data: {
          openID: this.openId,
          type: "check-login"
        }
      });
      const { isLoggedIn } = checkAuthResponse.data;
      app.SET_IS_LOADING(false);
      return isLoggedIn;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

import Vue from "vue";
import { NavigationGuard } from "vue-router";
import { auth } from "@/utils/LtkAuth0";

const getRedirectPath = () => {
  return localStorage.getItem("kakao-redirect-path");
};

const setRedirectPath = (path: string) => {
  localStorage.setItem("kakao-redirect-path", path);
};

const clearRedirectPath = () => {
  localStorage.removeItem("kakao-redirect-path");
};

const navigationGuard: NavigationGuard = async (to, from, next) => {
  if (window.navigator.userAgent.includes("KAKAOTALK")) {
    const fullPath = to.fullPath;
    if (to.meta && to.meta.title) {
      window.document.title = to.meta.title;
    }

    if (!to.meta.requiresAuth && to.name !== "Login") {
      const path2Redirect = getRedirectPath();
      if (path2Redirect) {
        clearRedirectPath();
        return next({ path: path2Redirect });
      } else if (fullPath === "/" || !to.name) {
        return next({ path: "logged-in" });
      }
      return next();
    }

    try {
      const accessToken = await auth.getAccessToken();
      const isAuthenticated = await auth.isAuthenticated();

      if (isAuthenticated) {
        const openId = Vue.$authentication.openId;
        if (openId) {
          const isLoggedIn = await Vue.$authentication.checkLoggedIn();
          if (!isLoggedIn) {
            await Vue.$authentication.lambdaAuthorize(accessToken, "login");
          }
        } else {
          window.alert(
            "알 수 없는 오류가 발생했습니다. 다시 시도하거나 계정 담당자에게 연락하세요."
          );
          return;
        }
        const path2Redirect = getRedirectPath();
        if (path2Redirect) {
          clearRedirectPath();
          return next({ path: path2Redirect });
        }
        return next();
      } else {
        setRedirectPath(fullPath);
        return await auth.login();
      }
    } catch (error) {
      if (
        error instanceof Error &&
        error.message.toLowerCase() === "login required"
      ) {
        if (fullPath !== "/logged-in") {
          setRedirectPath(fullPath);
        }
        return await auth.login();
      }
    }
    return next();
  } else {
    window.alert("해당 페이지는 카카오톡에서 다시 접속 부탁 드립니다. ");
    return;
  }
};

export default navigationGuard;

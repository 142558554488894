import { Auth, AuthOptions } from "@rscollabs/rs-ui-auth";
import router from "../../router";

const buildAuthConfig = () => {
  const config = window.rs_kakao;
  const authConfig: AuthOptions = {
    auth0: {
      clientOptions: {
        domain: config.auth0Domain,
        clientId: config.auth0ClientId,
        authorizationParams: {
          redirect_uri: config.redirectUri,
          audience: config.auth0Audience
        },
        useRefreshTokens: false,
        cacheLocation: "localstorage"
      },
      // You can enable the redirect to use, for example, your client app router here
      redirectCallback: (target: string) => {
        router.push(target);
      }
    }
  };

  return authConfig;
};

export const auth = new Auth(buildAuthConfig());











import { Vue, Component } from "vue-property-decorator";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import app from "@/store/app";

@Component({
  components: { LoadingSpinner }
})
export default class App extends Vue {
  get hasError() {
    return app.hasError;
  }
  get isLoading() {
    return app.isLoading;
  }
}

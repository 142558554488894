import {
  Module,
  VuexModule,
  Mutation,
  getModule
} from "vuex-module-decorators";
import store from "../../store";
import { AppState } from "@/store/app/types";

@Module({ dynamic: true, name: "app", store })
class App extends VuexModule implements AppState {
  public isVisible = false;
  public isLoading = false;
  public hasError = false;

  @Mutation
  public SET_IS_LOADING(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  public SET_HAS_ERROR(hasError = true) {
    this.hasError = hasError;
  }

  @Mutation
  public SET_IS_VISIBLE(isVisible: boolean) {
    this.isVisible = isVisible;
  }
}

export default getModule(App);

import _Vue, { PluginObject } from "vue";
import Authentication from "../utils/Authentication";

declare module "vue/types/vue" {
  interface VueConstructor {
    $authentication: Authentication;
  }
}

export class AuthenticationPlugin extends Authentication
  implements PluginObject<{}> {
  public install(Vue: typeof _Vue): void {
    Vue.$authentication = this;
  }
}

_Vue.use(new AuthenticationPlugin(), {});

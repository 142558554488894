import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import navigationGuard from "@/utils/Authentication/implicitOnNavigationGuard";
import { auth } from "@/utils/LtkAuth0";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () => import("../App.vue")
  },
  {
    path: "/login",
    name: "Login",
    meta: { requiresAuth: false },
    beforeEnter: async () => {
      return auth.login();
    }
  },
  {
    path: "/logged-in",
    name: "LoggedIn",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "loggedIn" */ "../views/LoggedIn.vue")
  },
  {
    path: "/welcome",
    name: "Welcome",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "welcome" */ "../views/Welcome.vue")
  },
  {
    path: "/kick-off",
    component: {
      // Inline declaration of a component that renders our <router-view>
      render: c => c("router-view")
    },
    children: [
      {
        name: "KickOffForm",
        path: "form",
        meta: {
          title: "LTK品牌入驻"
        },
        component: () =>
          import(
            /* webpackChunkName: "kick-off-form" */ "../views/KickOff/KickOffForm.vue"
          )
      },
      {
        name: "SubmitResult",
        path: "result",
        meta: {
          title: "LTK品牌入驻"
        },
        component: () =>
          import(
            /* webpackChunkName: "kick-off-form" */ "../views/KickOff/SubmitResult.vue"
          )
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(navigationGuard);

export default router;
